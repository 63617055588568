import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, title, pathname, image }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    url
                    siteUrl
                    image
                    social {
                        twitter
                    }
                }
            }
        }
    `)

    const metadata = {
        title: `${title} | ${site.siteMetadata.title}`,
        url: `${site.siteMetadata.url}${pathname || '/'}`,
        siteUrl: `${site.siteMetadata.siteUrl}`,
        image: site.siteMetadata.image,
        description: description || site.siteMetadata.description,
        author: site.siteMetadata.author,
        twitter: site.siteMetadata.social.twitter,
    }
    // const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | sappzo`}
            meta={[
                //general tags
                {
                    name: `description`,
                    content: metadata.description,
                },
                {
                    name: `image`,
                    content: metadata.image,
                },

                //opengraph tags
                {
                    property: `og:title`,
                    content: metadata.title,
                },
                {
                    property: `og:url`,
                    content: metadata.url,
                },
                {
                    property: `og:description`,
                    content: metadata.description,
                },
                {
                    property: `og:image`,
                    content: metadata.image,
                },
                {
                    property: `og:image:alt`,
                    content: metadata.description,
                },
                {
                    proprerty: `og:type`,
                    content: `website`,
                },

                //twitter card tags
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: metadata.twitter,
                },
                {
                    name: `twitter:title`,
                    content: metadata.title,
                },
                {
                    name: `twitter:description`,
                    content: metadata.description,
                },
                {
                    name: `twitter:image`,
                    content: `${metadata.siteUrl}${metadata.image}`,
                },
                {
                    name: `twitter:image:alt`,
                    content: metadata.description,
                },

            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: null,
    title: null,
    pathname: null,
    image: null,
}

SEO.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    pathname: PropTypes.string,
    image: PropTypes.string,
}

export default SEO