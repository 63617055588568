import React from 'react'
import './layout.css'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Layout = ({children}) => {

    return (
        <div className='full'>  
            <header>
                <nav>
                    <ul className='top-nav'>
                        <li><Link to='/'><img src={require('../../static/images/sappzo-logo.svg')} className='main-logo' alt='sappzo logo' align='middle'/></Link></li>
                        <li>
                            <ul className='media-links'>
                                <li key='twitter'>
                                    <OutboundLink href='https://twitter.com/sappzo'>
                                        <img src={require('../../static/images/social-media-icons/icon-twitter.svg')} alt='twitter link' />
                                    </OutboundLink>
                                </li>
                                <li key='youtube'>
                                    <OutboundLink href='https://www.youtube.com/channel/UCA67JRo1RLhUd1X2Xj_Xt0w'>
                                        <img src={require('../../static/images/social-media-icons/icon-youtube.svg')} alt='youtube link' />
                                    </OutboundLink>
                                </li>
                                <li key='facebook'>
                                    <OutboundLink href='https://www.facebook.com/sappzo'>
                                        <img src={require('../../static/images/social-media-icons/icon-facebook.svg')} alt='facebook link' />
                                    </OutboundLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className='content-nav'>
                        <li key='games'>
                            <Link 
                                className={'content-header'} 
                                to='/games/#games'
                            >
                                <h1>games</h1>
                            </Link>
                        </li>
                        <li key='devlogs'>
                            <Link 
                                className={'content-header'} 
                                to='/devlogs/#devlogs'
                            >
                                <h1>devlogs</h1>
                            </Link>
                        </li>
                        <li key='tutorials'>
                            <Link 
                                className={'content-header'} 
                                to='/tutorials/#note'
                            >
                                <h1>tutorials</h1>
                            </Link>
                        </li>
                        <li key='about'>
                            <Link 
                                className={'content-header'} 
                                to='/about/#aboutNote'
                            >
                                <h1>about</h1>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </header>

            <footer>
                <a href="/">© SAPPZO 2020</a>
            </footer>
            {children}
        </div>
    )
}

export default Layout


