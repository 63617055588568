import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './backgroundVideo.module.css'

export default ({initClip = 'TreeSwing'}) => {
    const [clip, setClip] = useState(initClip)

    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {ext: {eq: ".webm"}}) {
                edges {
                    node {
                        name
                    }
                }
            }
        } 
    `)

    return (
        <React.Fragment>
            <ul className={styles.playlist}>
            {
                data.allFile.edges.map(({node}, index) => (
                    <li key={index}>
                        <button aria-label={node.name} onClick={() => {
                                setClip(node.name)
                            }
                        }/>
                    </li>
                ))
            }
            </ul>
            <VideoClip clip={clip}/>
        </React.Fragment>
    )
}

export const VideoClip = ({ clip='TreeSwing' }) => {
    return (
        <video 
            key={clip} 
            poster={require(`../videos/${clip}.jpg`)} 
            className={styles.videoClip} 
            autoPlay
            playsInline
            muted 
            loop 
            controlsList='nodownload'
        >
            <source src={require(`../videos/${clip}.webm`)} type='video/webm'/>
            <source src={require(`../videos/${clip}.mp4`)} type='video/mp4'/>
            Sorry! Your browser does not support video.    
        </video>
    )
}

//poster={require(`../videos/${clip}.jpg`)}
//style={{backgroundImage: `url(${require(`../videos/${clip}.jpg`)})`}}
